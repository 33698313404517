import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import validate from 'validate.js';
import { withTranslation } from 'react-i18next';
import firebase from 'firebase/app';
import 'firebase/firestore';

const styles = theme => ({
    subscribeWrapper: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '500px',
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.down('sm')]: {
            maxWidth: 'calc(100vw - 2em)',
        }
    },
    textField: {
        width: '100%',
        flex: 1,
        color: 'rgba(0, 0, 0, 0.54)',
        '& label.Mui-focused': {
            color: 'rgba(0, 0, 0, 0.54) !important',
        },
        '& .Mui-focused fieldset': {
            borderColor: 'rgba(0, 0, 0, 0.54) !important',
        },
    },
    formButton: {
        width: '100%',
        minWidth: '170px',
        maxWidth: '100%',
        flex: 1,
        color: '#1f2d3d',
        padding: '1em',
        backgroundColor: '#00fbd5',
        '&:hover': {
            backgroundColor: '#00fbd5',
        }
    },
    title: {
        color: '#00c8aa',
    },
    errorMessage: {
        fontSize: '12px',
        color: '#DD0031',
        textAlign: 'left',
        marginBottom: '0.5em',
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
            width: '100%',
        }
    },
    ourseTitle: {
        fontFamily: "'Poiret One', cursive",
        fontSize: '2em',
    }
});

/**
 * @desc Subscribe component
 * @constructor
 * @author Miguel Aroles <miguelaroles@gmail.com>
 */
class Subscribe extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            buttonDisabled: false,
            recaptcha: false,
            errorEmail: '',
            submitted: false,
            db: firebase.firestore(),
        };
    }

    handleChange = (event) => {
        this.setState({email:event.target.value});
    };

    handleValidation = (event) => {
        let currentValue = event.target.value.trim();
        let validation = validate({email : currentValue}, {
            email: {
                email: {
                    message: "Please enter your email address in format yourname@example.com"
                }
            }
        });
        this.setState({ errorEmail : (validation)? "Please enter your email address in format yourname@example.com":''});
    };

    handleSubmit = () => {
        const {db} = this.state;
        let isNotValidEmail = validate({email: this.state.email}, {
            email: {
                email: {
                    message: "Please enter your email address in format yourname@example.com"
                }
            }
        });

        if(!isNotValidEmail) {
            this.setState({buttonDisabled : true});
            db.collection("emails").doc(`${this.state.email.trim()}`)
                .set({
                    to: `${this.state.email.trim()}`,
                    template: {
                        name: 'welcome',
                    }
                })
                .then(() => {
                    this.setState({
                        email : '',
                        buttonDisabled : false,
                        submitted: true,
                    });
                }).catch((error)=>{
                    this.setState(
                        { errorEmail : (isNotValidEmail)? "Please enter your email address in format yourname@example.com":'' }
                    );
            });

        } else {
            this.setState(
                { errorEmail : (isNotValidEmail)? "Please enter your email address in format yourname@example.com":'' }
            );
        }
    };

    render(){
        const {classes,t} = this.props;

        return(
            <div className={classes.subscribeWrapper}>
                {
                    (this.state.submitted)
                    ? <div>
                        <h2 className={classes.title}>{t("Thank you")}</h2>
                        <p>{t("Your subscription has been confirmed. \n You've been added to our list.")}</p>
                      </div>
                    : <span>
                        <p className={classes.ourseTitle}>{t("Coming Soon")}</p>
                        <p className={classes.introText}>
                            {t("We are pleased to announce the launch of our website's beta program: OURSE ")}<br/>
                            {t("Before the official launch, take advantage of a ")} <strong>{t("very Limited Opportunity")}</strong> {t("by subscribing to this program. ")}<br/>
                            {t("Click on the following button to enroll.")}
                        </p>
                        <TextField
                            error={Boolean(this.state.errorEmail)}
                            id="subscribe-email"
                            label="Email"
                            className={classes.textField}
                            value={this.state.email}
                            onChange={this.handleChange}
                            onBlur={this.handleValidation}
                            margin="normal"
                            variant="outlined"
                            type="email"
                            name="email"
                        />
                        <span className={classes.errorMessage}>{t(this.state.errorEmail)}</span>
                        <Button
                            aria-label={t("Join the movement")}
                            variant="contained"
                            className={classes.formButton}
                            disabled={this.state.buttonDisabled}
                            onClick={this.handleSubmit}>
                            {
                                (this.state.buttonDisabled)
                                    ? <CircularProgress size={20} style={{color : '#FFFFFF'}} />
                                    : t("Join the movement")
                            }
                        </Button>
                    </span>
                }
            </div>
        );
    }
}

export default withStyles(styles)(withTranslation('app')(Subscribe));