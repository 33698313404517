import React, {Component} from 'react';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DoneIcon from '@material-ui/icons/Undo';
import firebase from 'firebase/app';
import 'firebase/firestore';
import Grid from '@material-ui/core/Grid';
import validate from 'validate.js';
import Recaptcha from 'react-recaptcha';
import SendIcon from '@material-ui/icons/Send';
import TextField from '@material-ui/core/TextField';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';

const styles = theme => ({
    contactFormWrapper: {
        flex: 1,
        minHeight: '433px',
        '& .rc-anchor-dark': {
            backgroundColor: '#2e353f !important',
        },
        [theme.breakpoints.down('sm')]: {
            '& #g-recaptcha > div': {
                marginLeft: 'auto',
                marginRight: 'auto',
            }
        }
    },
    formInput: {
        width: 'calc(100% - 2em)',
        color: '#fff',
        '& label':{
            color: '#eee',
        },
        '& input':{
            color: '#FFFFFF',
        },
        '& textarea':{
            color: '#FFFFFF',
        },
        '& .MuiFilledInput-underline::before': {
            borderBottomColor: 'rgba(0,0,0,0.1)',
            borderWidth: 'initial'
        },
        [theme.breakpoints.down('sm')]:{
            width: 'calc(100% - 0em)',
        }
    },
    formSubtext: {
        fontSize: '0.8em',
        color: '#939da5',
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        width: '100%',
        position: 'relative',
        marginTop: '0.5em',
        marginBottom: '2em'
    },
    formButton: {
        boxShadow: 'none',
        backgroundColor: '#FFFFFF',
        marginTop: '1em',
        padding: '1em',
        minWidth: '190px',
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 0px)',
        }
    },
    errorMessage: {
        color: '#d50000',
        position: 'relative',
        top: '4px',
        left: '4px',
        '&.spacing-top': {
            marginBottom: '1em',
            display: 'block',
        }
    },
    icon: {
        marginRight: '4px',
        display: 'inline-block',
        verticalAlign: 'middle'
    },
    buttonTextInner: {
        verticalAlign: 'middle',
        display: 'inline-block'
    }
});

/**
 * @desc Contact component
 * @constructor
 * @author Miguel Aroles <miguelaroles@gmail.com>
 */
class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            constraint : {
                email : {message: props.t("Please enter your email address in format yourname@example.com")}
            },
            open : false,
            name : '',
            company : '',
            email : '',
            phone : '',
            message : '',
            errorName : '',
            errorCompany : '',
            errorEmail : '',
            errorPhone : '',
            errorMessage : '',
            errorRecaptcha : '',
            buttonDisabled : false,
            recaptcha: false,
            db: firebase.firestore(),
        };
    }

    handleClose = () => {
        this.setState({ open: false });
    };

    handleSubmit = (event) => {
        const {
            db,
            name,
            company,
            email,
            phone,
            message
        } = this.state;
        event.preventDefault();
        event.stopPropagation();
        let isNotValidEmail = validate({email: this.state.email}, {
            email: {
                email: {
                    message: "Please enter your email address in format yourname@example.com"
                }
            }
        });

        const resetState = () =>{
            this.setState({
                open : true,
                name : '',
                company : '',
                email : '',
                phone : '',
                message : '',
                errorRecaptcha: '',
                recaptcha: false,
                buttonDisabled : false
            });
        };

        if(this.state.name && this.state.message && !isNotValidEmail && this.state.recaptcha) {
            this.setState({buttonDisabled : true});
            if(!isNotValidEmail) {
                this.setState({buttonDisabled : true});
                db.collection("emails")
                .add({
                    to: `${this.state.email.trim()}`,
                    template: {
                        name: 'support',
                        data:{
                            name: this.state.name.trim()
                        }
                    }
                }).then(() =>{
                    resetState();
                }).catch((error)=>{
                    resetState();
                });

                db.collection("emails")
                .add({
                    to: 'support@ourse.app',
                    template: {
                        name: 'admin',
                        data:{
                            name : name.trim(),
                            company : company.trim(),
                            email : email.trim(),
                            phone : phone.trim(),
                            message : message.trim(),
                        }
                    }
                })
                .then(() => {});

            }
        } else {
            this.setState(
                {
                        errorRecaptcha: (!this.state.recaptcha)?"Please verify that you are a human!":'',
                        errorName: (!this.state.name)?"This field is required":'',
                        errorMessage: (!this.state.message)?"This field is required":'',
                        errorEmail : (isNotValidEmail)?"Please enter your email address in format yourname@example.com":'',
                    }
                );

        }
    };

    handleValidation = (event) => {
        let targetName = event.target.name;
        let currentValue = event.target.value.trim();
        let errorIdentifier = `error${ targetName.charAt(0).toUpperCase() + targetName.slice(1)}`;
        const requiredErrorMessage = "This field is required";

        if(targetName === 'email'){
            let validation = validate({email : currentValue}, {
                email: {
                    email: {
                        message: "Please enter your email address in format yourname@example.com"
                    }
                }
            });

            if(validation){
                this.setState({ [errorIdentifier] : "Please enter your email address in format yourname@example.com" });
            } else {
                this.setState({ [errorIdentifier] : '' });
            }
        } else {
            if(validate.isEmpty(currentValue)){
                this.setState({ [errorIdentifier] : requiredErrorMessage });
            } else {
                this.setState({ [errorIdentifier] : '' });
            }
        }
    };

    handleChange = (event) => {
        this.setState({[event.target.name] : event.target.value})
    };

    // specifying your onload callback function
    callback = () => {
        //console.log('/// onload Done!!!! ///');
    };

    // specifying verify callback function
    verifyCallback = (response) => {
        if(response){
            //console.log('/// verify callback response : ///',response);
            this.setState({recaptcha: true});
        }
    };

    render() {
        const { classes,t } = this.props;
        return(
            <div>
                <div className={classes.contactFormWrapper}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="name"
                                type="text"
                                className={classes.formInput}
                                label={t("* Name")}
                                variant="filled"
                                onChange={this.handleChange}
                                onBlur={this.handleValidation}
                                name="name"
                                data-name="Name"
                                value={this.state.name}
                                helperText={(this.state.errorName)?t(this.state.errorName):''}
                                error={!!this.state.errorName}
                                inputProps={{
                                    required: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                type="email"
                                id="email"
                                className={classes.formInput}
                                label={t("* Email Address")}
                                variant="filled"
                                onChange={this.handleChange}
                                onBlur={this.handleValidation}
                                name="email"
                                data-name="Email Address"
                                value={this.state.email}
                                helperText={(this.state.errorEmail)?t(this.state.errorEmail):''}
                                error={!!this.state.errorEmail}
                                inputProps={{
                                    required: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <div className="form-group">
                                <TextField
                                    multiline
                                    type="text"
                                    id="text-area"
                                    className={`${classes.formInput}`}
                                    label={t("* Your message here... ")}
                                    variant="filled"
                                    onChange={this.handleChange}
                                    onBlur={this.handleValidation}
                                    name="message"
                                    data-name="Text Area"
                                    value={this.state.message}
                                    helperText={(this.state.errorMessage)?t(this.state.errorMessage):''}
                                    error={!!this.state.errorEmail}
                                    rows={4}
                                    inputProps={{
                                        required: true,
                                    }}
                                />
                            </div>
                            <span className={classes.formSubtext}>{t("* Required fields")}</span>
                            <Recaptcha
                                className={classes.recaptcha}
                                sitekey="6Lck4VkUAAAAADqsfbChq2lx0zkVIfkZ2jCtJ0Yb"
                                render="explicit"
                                verifyCallback={this.verifyCallback}
                                onloadCallback={this.callback}
                            />
                            <span className={classes.errorMessage}>{t(this.state.errorRecaptcha)}</span>
                        </Grid>
                    </Grid>
                    <Button
                        aria-label={t("Send my Message ")}
                        variant="contained"
                        className={classes.formButton}
                        disabled={this.state.buttonDisabled}
                        onClick={this.handleSubmit}>
                        {(this.state.buttonDisabled)
                            ? <CircularProgress size={20} style={{color : '#FFFFFF'}} />
                            : <span><SendIcon className={classes.icon}/><span className={classes.buttonTextInner}>{t("Send my Message ")}</span></span>
                        }
                    </Button>
                </div>

                <Dialog
                    fullScreen={false}
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle
                        id="responsive-dialog-title"
                        style={{textAlign : 'center'}}
                    >
                    </DialogTitle>
                    <DialogContent>
                        <Alert severity="success">{t("Your message has been successfully sent!")}</Alert>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            aria-label={t("Close")}
                            onClick={this.handleClose}
                            color="primary"
                            variant="contained"
                            style={{
                                backgroundColor: '#2196f3',
                                color: '#FFFFFF',
                                fontSize: '0.8em',
                                minWidth: '132px',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                            }}
                        >
                            <DoneIcon style={{
                                marginRight: '0.5em'
                            }}/> {t("Close")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(withMobileDialog()(withTranslation('app')(Contact)));