import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import LockIcon from '@material-ui/icons/Lock';
import Subscribe from './Subscribe';
import { withTranslation } from 'react-i18next';
const styles = theme => ({
    Info: {
        display: 'flex',
        padding: '1em',
        textAlign: 'center',
        position: 'relative',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    brandLogo: {
        width: 280,
        height: 65,
        [theme.breakpoints.down('sm')]: {
            marginBottom: '1em',
            maxWidth: '100%',
        }
    },
    content: {
        color: '#939da5',
        lineHeight: 1.8,
        [theme.breakpoints.down('sm')]: {
            lineHeight: 1.4,
            '@media only screen and (min-device-width : 375px) and (max-device-height : 812px) and (-webkit-device-pixel-ratio : 3) and (orientation : landscape)': {
                paddingLeft: '6em',
                paddingRight: '6em',
            }
        }
    },
    textContainer: {
        maxWidth: '600px',
        margin: 'auto',
    },
    button: {
        position: 'fixed',
        top: '1em',
        right: '1em',
        color: '#939da5',
        [theme.breakpoints.down('sm')]: {
            position: 'sticky',
            width: '52px',
            float: 'right',
            borderRadius: '4px',
        }
    },
    submitButton: {
        color: '#FFFFFF',
        alignSelf: 'center',
        display: 'inline-flex',
        background: '#00c8aa',
        fontWeight: 800,
        letterSpacing: '1px',
        padding: '0.8em 2em',
        borderRadius: '10rem',
        border: 'none',
        zIndex: 999,
        textTransform: 'uppercase',
        overflow: 'hidden',
        boxShadow: 'none',
        minWidth: '10em',
        marginTop: '2em',
        '& a': {
            textDecoration: 'none',
            color: '#FFFFFF',
        },
        '&:hover': {
            background: '#00fbd5',
            borderColor: '#00c8aa',
            color: '#FFFFFF',
            transition: 'all 0.3s ease-out',
        },
        '&:visited': {
            background: '#00c8aa',
            color: '#FFFFFF',
        }
    },
    agreementContainer: {
        marginTop: '2em',
        [theme.breakpoints.down('sm')]: {
            marginTop: '1em',
        }
    },
    checkBoxButton: {
        marginLeft: 'auto',
        marginRight: 'auto',
        '& span[class*="MuiFormControlLabel-label-"]': {
            color: '#939da5',
        }
    },
    spamNews: {
        color: '#939da5',
        fontSize: '0.6em'
    },
    lockIcon: {
        fontSize: '1.5em',
        position: 'relative',
        top: '2px',
    },
    errorMessageContainer: {
        fontSize: '0.8em',
        color: '#FF0000',
    }
});

/**
 * @desc Infos component
 * @class
 * @author Miguel Aroles <miguelaroles@gmail.com>
 */
class Infos extends Component {
    constructor(props) {
        super(props);
        const {t} = props;
        this.state = {
            constraint : {
                email : {message: t("Please enter your email address in format yourname@example.com")}
            },
            email : '',
            errorEmail : '',
            agree : false,
            errorAgree : '',
            redirect : false
        };
    }

    handleSubmit = (event) => {
        const {t} = this.props;
        event.preventDefault();
        event.stopPropagation();

        if(this.state.agree) {
            this.setState({errorAgree : ''});
        } else {
            this.setState({errorAgree : t("Please accept the terms and conditions described in the disclaimer before continuing.")});
        }
    };

    handleClose = () => {
        this.props.toggleDrawer(false);
    };

    render() {
        const { classes,t } = this.props;

        return(
            <div className={classes.Info}>
                <Button aria-label={'Close'} onClick={this.handleClose} variant="text" className={classes.button}><CloseIcon fontSize="large"/></Button>
                <div className={classes.content}>
                    <img src={"./img/logo.svg"} alt="Logo" className={classes.brandLogo}/>
                    <Subscribe />
                </div>
                <p className={classes.spamNews}><LockIcon className={classes.lockIcon} /> {t("We hate spam just as much as you do. That's why we guarantee your email will stay secret.")}</p>
            </div>
        );
    }
}

export default withStyles(styles)(withTranslation('app')(Infos));