import React from 'react';
import ReactDOM from 'react-dom';
import './assets/index.css';
import App from './component/App';
import firebase from 'firebase/app';
import 'firebase/app';
import * as serviceWorker from './serviceWorker';
import './i18n.js';
import i18n from "i18next";
import {detectBrowserLanguage} from './utils/constants';

window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

let config = {
    apiKey: "AIzaSyBzBj-zt7iSQfaIy1BEg7oFECr6_z3wLmE",
    authDomain: "landing-page-6d0c8.firebaseapp.com",
    databaseURL: "https://landing-page-6d0c8.firebaseio.com",
    projectId: "landing-page-6d0c8",
    storageBucket: "gs://landing-page-6d0c8",
    messagingSenderId: "35793046018"
};

firebase.initializeApp(config);
firebase.firestore().settings({ experimentalForceLongPolling: true });

const UserDefaultLanguage = (detectBrowserLanguage().split('-')[0].toLowerCase() === 'fr')?'fr':'en';
i18n.changeLanguage(UserDefaultLanguage);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
