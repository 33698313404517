
let en = {
    app: {
        "Coming Soon": "Coming Soon",
        "Find a sporting event near you": "Find a sporting event near you",
        "We are pleased to announce the launch of our website's beta program: OURSE ": "We are pleased to announce the launch of our website's beta program: OURSE ",
        "The social platform for finding sport and well-being activities near you.": "The social platform for finding sport and well-being activities near you.",
        "Before the official launch, take advantage of a ": "Before the official launch, take advantage of a ",
        "very Limited Opportunity": "very Limited Opportunity",
        "by subscribing to this program. ": "by subscribing to this program. ",
        "Click on the following button to enroll.": "Click on the following button to enroll.",
        "More Information": "More Information",
        "Our Project": "Our Project",
        "Do you wish to participate in sports, run, dance, or do other physical activities, but are unable to do it on your own or don't know where to go near your home? Do you want to find more players for your football team, find a running or a tennis partner?": "Do you wish to participate in sports, run, dance, or do other physical activities, but are unable to do it on your own or don't know where to go near your home? Do you want to find more players for your football team, find a running or a tennis partner?",
        "Or perhaps you are a coach, a dance teacher, or a yoga teacher and are interested in starting your activity as the head of a club or team. Do you want to pass on your knowledge joyfully, but don't know how to make yourself visible?": "Or perhaps you are a coach, a dance teacher, or a yoga teacher and are interested in starting your activity as the head of a club or team. Do you want to pass on your knowledge joyfully, but don't know how to make yourself visible?",
        "Ourse is the social platform created for you that makes all events related to your interests in your geographical area visible at a glance on an interactive map.": "Ourse is the social platform created for you that makes all events related to your interests in your geographical area visible at a glance on an interactive map.",
        "Planning/scheduling tools and a platform for exchanging messages facilitate the organization of public or private groups and events, both remotely or in-person.": "Planning/scheduling tools and a platform for exchanging messages facilitate the organization of public or private groups and events, both remotely or in-person.",
        "Don't lose the opportunity to explore a new avenue to stay healthy and in a good mood.": "Don't lose the opportunity to explore a new avenue to stay healthy and in a good mood.",
        "Ourse provides you with the tools, resources, and guidance you need to help you lead a healthier life.": "Ourse provides you with the tools, resources, and guidance you need to help you lead a healthier life.",
        "The Functionalities": "The Functionalities",
        "Ourse has everything you would expect from an advanced management platform, and more!": "Ourse has everything you would expect from an advanced management platform, and more!",
        "Interactive Map": "Interactive Map",
        "Ourse is an easy-to-use tool to help you find the right event for you based on your availability and location mobility. The search filter allows you to see all your options at a glance and in one click, choose the one that suits you.":"Ourse is an easy-to-use tool to help you find the right event for you based on your availability and location mobility. The search filter allows you to see all your options at a glance and in one click, choose the one that suits you.",
        "Diet and Training plan":"Diet and Training plan",
        "Ourse offers you a platform to exchange messages and publications so that you can interact with all your groups and meet new friends. Ourse also offers a range of solutions that will help you easily share documents like sports programs and meal plans.":"Ourse offers you a platform to exchange messages and publications so that you can interact with all your groups and meet new friends. Ourse also offers a range of solutions that will help you easily share documents like sports programs and meal plans.",
        "Planning Tools":"Planning Tools",
        "Use the planning module to easily create complete and optimized planning according to your needs. Reservations will automatically appear in your calendar. All subscribers have a profile that you can consult to get to know your audience and adjust your approach if necessary.":"Use the planning module to easily create complete and optimized planning according to your needs. Reservations will automatically appear in your calendar. All subscribers have a profile that you can consult to get to know your audience and adjust your approach if necessary.",
        "Drive Generator": "Drive Generator",
        "Unlike conventional fitness apps, Ourse uses data and algorithms to create a computer model of the user's level of fitness, from which it will generate optimal targeted training according to their personal needs.":"Unlike conventional fitness apps, Ourse uses data and algorithms to create a computer model of the user's level of fitness, from which it will generate optimal targeted training according to their personal needs.",
        "The knowledge of the professional coaches in tandem with our awesome AI crammed in the app helps coaches to make workout sessions more productive and to achieve better results.":"The knowledge of the professional coaches in tandem with our awesome AI crammed in the app helps coaches to make workout sessions more productive and to achieve better results.",
        "Payment System": "Payment System",
        "Increase your revenue with payment options designed to reach a global audience. Your courses can also be delivered online to broaden your potential client base.":"Increase your revenue with payment options designed to reach a global audience. Your courses can also be delivered online to broaden your potential client base.",
        "Personal Coaching":"Personal Coaching",
        "Go beyond your limits with an individual program designed for you. After an initial personalized evaluation, the coach guides you through a series of objectives.":"Go beyond your limits with an individual program designed for you. After an initial personalized evaluation, the coach guides you through a series of objectives.",
        "Our Team":"Our Team",
        "FOUNDER - CEO":"FOUNDER - CEO",
        "Miguel is a developer, artist, and entrepreneur. Passionate about new technologies and their positive impacts on the society, he founded and developed Ourse to help communities in their quest for a healthier life.":"Miguel is a developer, artist, and entrepreneur. Passionate about new technologies and their positive impacts on the society, he founded and developed Ourse to help communities in their quest for a healthier life.",
        "COMMUNICATION MANAGER":"COMMUNICATION MANAGER",
        "Julie is a professional architect and the communication manager of Ourse. Sensitive to the dynamics of social cohesion through the appropriation of public space, Julie has joined forces with the project Ourse, a real social catalyst.":"Julie is a professional architect and the communication manager of Ourse. Sensitive to the dynamics of social cohesion through the appropriation of public space, Julie has joined forces with the project Ourse, a real social catalyst.",
        "Get in touch":"Get in touch",
        "Contact us via ":"Contact us via ",
        "Email":"Email",
        "This field is required": "This field is required",
        "Send my Message ":"Send my Message ",
        "Close":"Close",
        "* Name":"* Name",
        "* Email Address":"* Email Address",
        "* Your message here... ":"* Your message here... ",
        "* Required fields":"* Required fields",
        "Your message has been successfully sent!":"Your message has been successfully sent!",
        "  - To make the world a better place": "  - To make the world a better place",
        "Enroll for free":"Enroll for free",
        "Please enter your email address in format yourname@example.com":"Please enter your email address in format yourname@example.com",
        "Please accept the terms and conditions described in the disclaimer before continuing.":"Please accept the terms and conditions described in the disclaimer before continuing.",
        "Please note that this is a":"Please note that this is a",
        "Beta version ":"Beta version ",
        "of the OURSE website which is still undergoing final testing before its official release. The website, its software and all content found on it are provided on an \"as is\" and \"as available\" basis. OURSE does not give any warranties, whether express or implied, as to the suitability or usability of the website, its software or any of its content.":"of the OURSE website which is still undergoing final testing before its official release. The website, its software and all content found on it are provided on an \"as is\" and \"as available\" basis. OURSE does not give any warranties, whether express or implied, as to the suitability or usability of the website, its software or any of its content.",
        "Should you encounter any bugs, glitches, lack of functionality or other problems on the website, please let us know immediately so we can rectify these accordingly":"Should you encounter any bugs, glitches, lack of functionality or other problems on the website, please let us know immediately so we can rectify these accordingly",
        "(support@ourse.app)":"(support@ourse.app)",
        ". Your help in this regard is greatly appreciated.":". Your help in this regard is greatly appreciated.",
        "Check here to indicate that you have read and agree.": "Check here to indicate that you have read and agree.",
        "I Agree":"I Agree",
        "We hate spam just as much as you do. That's why we guarantee your email will stay secret.":"We hate spam just as much as you do. That's why we guarantee your email will stay secret.",
        "Join the movement":"Join the movement",
        "Thank you":"Thank you",
        "Your subscription has been confirmed. \n You've been added to our list.":"Your subscription has been confirmed. \n You've been added to our list.",
        "Please verify that you are a human!":"Please verify that you are a human!"
    }
};

export default en;