import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import en from "./translations/en.js";
import fr from "./translations/fr.js";


i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: "en",
    fallbackLng: "en", // use en if detected lng is not available
    resources: {
      en: en,
      fr: fr,
    },
    ns: ["app"],
    defaultNS: "app",
    saveMissing: true,
    saveMissingTo: 'current',
    keySeparator: '>',
    nsSeparator: '|',
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;