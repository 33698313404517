
let fr = {
    app: {
        "Coming Soon": "Prochainement",
        "Find a sporting event near you": "Trouvez un événement sportif près de chez vous",
        "We are pleased to announce the launch of our website's beta program: OURSE ": "Nous sommes heureux de vous annoncer le lancement du programme bêta de notre site web : OURSE ",
        "The social platform for finding sport and well-being activities near you.": "La plateforme sociale pour le sport et le bien-être à côté de chez vous.",
        "Before the official launch, take advantage of a ": "Avant le lancement officiel, profitez d’une ",
        "very Limited Opportunity": "opportunité très limitée",
        "by subscribing to this program. ": "en vous abonnant à ce programme. ",
        "Click on the following button to enroll.": "Cliquez sur le bouton suivant pour vous inscrire.",
        "More Information": "Plus d'information",
        "Our Project": "Notre Projet",
        "Do you wish to participate in sports, run, dance, or do other physical activities, but are unable to do it on your own or don't know where to go near your home? Do you want to find more players for your football team, find a running or a tennis partner?": "Vous souhaitez faire du sport, courir, danser, ou pratiquer d'autres activités physiques, mais vous n’osez pas vous lancer seul ou vous ne savez pas où en faire à côté de chez vous? Vous voulez compléter votre équipe de football ou trouver un partenaire de course ou de tennis?",
        "Or perhaps you are a coach, a dance teacher, or a yoga teacher and are interested in starting your activity as the head of a club or team. Do you want to pass on your knowledge joyfully, but don't know how to make yourself visible?": "Ou alors vous êtes coach, professeur de danse ou professeur de yoga et vous aimeriez commencer votre activité en tant que responsable d'un club ou d'une équipe. Vous avez à cœur de transmettre joyeusement votre savoir, mais vous ne savez pas comment vous rendre visible?",
        "Ourse is the social platform created for you that makes all events related to your interests in your geographical area visible at a glance on an interactive map.": "Ourse est la plateforme sociale créée pour vous qui rend visible en un clin d’œil sur une carte interactive tous les événements liés à vos centres d’intérêts dans votre zone géographique.",
        "Planning/scheduling tools and a platform for exchanging messages facilitate the organization of public or private groups and events, both remotely or in-person.": "Des outils de planification et une plateforme d'échange de messages facilitent l'organisation de groupes et d'événements publics ou privés, à distance ou en personne.",
        "Don't lose the opportunity to explore a new avenue to stay healthy and in a good mood.": "Ne perdez pas l'occasion d'explorer une nouvelle voie pour rester en bonne santé et de bonne humeur.",
        "Ourse provides you with the tools, resources, and guidance you need to help you lead a healthier life.": "Ourse vous fournit simplement les outils, les ressources, et l’encadrement dont vous avez besoin pour vous aider à avoir une vie plus saine.",
        "The Functionalities": "Les Fonctionnalités",
        "Ourse has everything you would expect from an advanced management platform, and more!": "Ourse possède tout ce que l'on attend d'une plateforme de gestion avancée et plus encore.",
        "Interactive Map": "Carte Interactive",
        "Ourse is an easy-to-use tool to help you find the right event for you based on your availability and location mobility. The search filter allows you to see all your options at a glance and in one click, choose the one that suits you.":"Ourse est un outil facile d’utilisation pour vous aider à trouver l'événement qui vous convient le mieux en fonction de vos disponibilités et de votre mobilité géographique. La recherche par filtre permet de visualiser en un clin d’œil et en un clic de choisir celle qui vous convient.",
        "Diet and Training plan":"Plan d'entraînement",
        "Ourse offers you a platform to exchange messages and publications so that you can interact with all your groups and meet new friends. Ourse also offers a range of solutions that will help you easily share documents like sports programs and meal plans.":"Ourse met à votre disposition une plateforme d’échange de messages et de publications afin que vous puissiez interagir avec tous vos groupes et rencontrer de nouveaux amis. Ourse vous offre également un panel de solutions qui vous aideront à partager facilement des documents tels que des programmes sportifs et des régimes alimentaires.",
        "Planning Tools":"Outils de Planification",
        "Use the planning module to easily create complete and optimized planning according to your needs. Reservations will automatically appear in your calendar. All subscribers have a profile that you can consult to get to know your audience and adjust your approach if necessary.":"Utilisez le module de planification pour créer facilement un planning complet et optimisé en fonction de vos besoins. Les réservations apparaîtront automatiquement dans votre calendrier. Tous les abonnés ont un profil que vous pouvez consulter pour connaître votre public et ajuster votre approche si nécessaire.",
        "Drive Generator": "Générateur d'entrainements",
        "Unlike conventional fitness apps, Ourse uses data and algorithms to create a computer model of the user's level of fitness, from which it will generate optimal targeted training according to their personal needs.":"Contrairement aux applications de fitness conventionnelles, Ourse utilise des données et des algorithmes pour créer un modèle informatique du niveau de fitness de l'utilisateur, à partir duquel il va générer un entraînement ciblé et optimal, en fonction de ses besoins personnels.",
        "The knowledge of the professional coaches in tandem with our awesome AI crammed in the app helps coaches to make workout sessions more productive and to achieve better results.":"Les connaissances des coachs professionnels, associées à notre puissante IA, aident les entraîneurs à rendre les séances d'entraînement plus productives et à obtenir de meilleurs résultats.",
        "Payment System": "Système de Paiements",
        "Increase your revenue with payment options designed to reach a global audience. Your courses can also be delivered online to broaden your potential client base.":"Augmentez vos revenus grâce à des options de paiement conçues pour toucher un public mondial. Vos cours peuvent également être dispensés en ligne afin d'élargir votre clientèle potentielle.",
        "Personal Coaching":"Coaching Personnel",
        "Go beyond your limits with an individual program designed for you. After an initial personalized evaluation, the coach guides you through a series of objectives.":"Dépassez vos limites grâce à un programme individuel conçu pour vous. Après une première évaluation personnalisée, le coach vous guide à travers une série d'objectifs à atteindre.",
        "Our Team":"Notre Équipe",
        "FOUNDER - CEO":"FOUNDER - CEO",
        "Miguel is a developer, artist, and entrepreneur. Passionate about new technologies and their positive impacts on the society, he founded and developed Ourse to help communities in their quest for a healthier life.":"Miguel est développeur, artiste et entrepreneur. Passionné de nouvelles technologies et de leurs impacts positifs sur la société, il a fondé et développé Ourse dans le but d'aider les communautés dans leur quête d'une vie plus saine.",
        "COMMUNICATION MANAGER":"COMMUNICATION MANAGER",
        "Julie is a professional architect and the communication manager of Ourse. Sensitive to the dynamics of social cohesion through the appropriation of public space, Julie has joined forces with the project Ourse, a real social catalyst.":"Julie est architecte de profession et responsable communication d'Ourse. Sensible aux dynamiques de cohésions sociales par l’appropriation de l'espace public, Julie s'est alliée au projet Ourse, un véritable catalyseur social.",
        "Get in touch":"Contactez-nous",
        "Contact us via ":"Vous pouvez nous joindre par ",
        "Email":"Email",
        "This field is required": "Ce champs est requis",
        "Send my Message ":"Envoyer mon Message",
        "Close":"Fermer",
        "* Name":"* Nom",
        "* Email Address":"* Adresse Email",
        "* Your message here... ":"* Votre message ici... ",
        "* Required fields":"* Champs requis",
        "Your message has been successfully sent!":"Votre message a été envoyé avec succès!",
        "  - To make the world a better place": " - Pour un monde meilleur",
        "Enroll for free":"S'inscrire gratuitement",
        "Please enter your email address in format yourname@example.com":"Veuillez saisir votre adresse électronique au format yourname@example.com",
        "Please accept the terms and conditions described in the disclaimer before continuing.":"Veuillez accepter les termes et conditions décrits dans la clause de non-responsabilité avant de continuer.",
        "Please note that this is a":"Veuillez noter qu'il s'agit d'une",
        "Beta version ":"version Bêta ",
        "of the OURSE website which is still undergoing final testing before its official release. The website, its software and all content found on it are provided on an \"as is\" and \"as available\" basis. OURSE does not give any warranties, whether express or implied, as to the suitability or usability of the website, its software or any of its content.":"du site Web Ourse, qui est toujours en phase de test final avant sa sortie officielle. le site Web, son logiciel et tout le contenu qui s'y trouve, sont fournis \"Tel quel\" et \"selon disponibilité du service\". Ourse ne donne aucune garantie, explicite ou implicite, quant à la pertinence ou à la convivialité du site, son logiciel ou tout son contenu.",
        "Should you encounter any bugs, glitches, lack of functionality or other problems on the website, please let us know immediately so we can rectify these accordingly":"Si vous rencontrez des bugs, des problèmes, un manque de fonctionnalité ou autres problèmes sur le site Web, veuillez nous le signaler immédiatement afin que nous puissions les corriger",
        "(support@ourse.app)":"(support@ourse.app)",
        ". Your help in this regard is greatly appreciated.":". Votre aide sera grandement appréciée.",
        "Check here to indicate that you have read and agree.": "Cochez ici pour indiquer que vous avez lu et accepté.",
        "I Agree":"j'accepte",
        "We hate spam just as much as you do. That's why we guarantee your email will stay secret.":"Nous détestons le spam, tout autant que vous. C'est pourquoi nous vous garantissons que votre email restera secret.",
        "Join the movement":"Rejoindre le mouvement",
        "Thank you":"Merci beaucoup",
        "Your subscription has been confirmed. \n You've been added to our list.":"Votre abonnement a été confirmé. \n Vous avez été ajouté à notre liste.",
        "Please verify that you are a human!":"Veuillez vérifier que vous êtes un humain!"
    }
};

export default fr;