/* OURSE APP URL */
export const PROD_REGISTER_URL = 'https://www.ourse.app/#/register';
export const DEV_REGISTER_URL = 'http://localhost:3000/#/register';

/* SOCIAL URL */
export const SOCIAL_FACEBOOK = 'https://www.facebook.com/Ourse-1959556191025653/';
export const SOCIAL_TWITTER = 'https://twitter.com/OurseApp';
export const SOCIAL_INSTAGRAM = 'https://www.instagram.com/ourseapp/';

/* GITHUB */
export const GITHUB_URL = 'https://github.com/miguelaroles';

/* SUPPORT MAIL */
export const SUPPORT_MAIL = 'support@ourse.app';

/* BROWSER LANGUAGE */
export const detectBrowserLanguage = () => (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;
