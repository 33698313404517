import React,{Component} from 'react';
import '../assets/App.css';
import ReactFullpage from '@fullpage/react-fullpage';
import {
  SOCIAL_FACEBOOK,
  SOCIAL_TWITTER,
  SOCIAL_INSTAGRAM,
  SUPPORT_MAIL,
} from '../utils/constants';
import {
  isTablet,
} from "react-device-detect";
import Info from './Info';
import ContactComponent from './Contact';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import withWidth from '@material-ui/core/withWidth';
import SwipeableViews from 'react-swipeable-views';
import MobileStepper from '@material-ui/core/MobileStepper';
import { autoPlay } from 'react-swipeable-views-utils';
import Arrow from '@material-ui/icons/KeyboardArrowDown';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import FaceIcon from '@material-ui/icons/Face';
import RoomIcon from '@material-ui/icons/Room';
import PublicIcon from '@material-ui/icons/Public';
import InfoIcon from '@material-ui/icons/Info';
import DateRangeIcon from '@material-ui/icons/DateRange';
import FitnessIcon from '@material-ui/icons/FitnessCenter';
import StarIcon from '@material-ui/icons/StarRate';
import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import i18n from "i18next";
import DownIcon from '@material-ui/icons/ArrowDropDown';

import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF,faTwitter,faInstagram } from '@fortawesome/free-brands-svg-icons';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';

library.add(faFacebookF,faTwitter,faInstagram);

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  appTitle: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '3em',
    }
  },
  list: {
    width: '50vw',
    height: '100vh',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:focus':{
      outline: 'none'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
      height: 'auto',
    }
  },
  fullList: {
    width: 'auto',
  },
  button: {
    color: '#1f2d3d',
    alignSelf: 'center',
    display: 'inline-flex',
    background: '#00fbd5',
    fontWeight: 800,
    letterSpacing: '1px',
    padding: '0.8em 2em',
    borderRadius: '10rem',
    border: 'none',
    zIndex: 999,
    textTransform: 'uppercase',
    overflow: 'hidden',
    boxShadow: 'none',
    position: 'relative',
    '&.cto-button': {
      marginTop: '0.75em',
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.775rem',
      },
      '@media only screen and (max-device-width: 320px)': {
        fontSize: '0.675rem',
      },
    },
    '& .appearHover': {
        display: 'none',
        position: 'absolute',
        right: '0.7em',
        visibility: 0,
    },
    '&:hover .appearHover': {
        display: 'initial',
        transition: 'all 1s ease-out',
        fontSize: '2em',
        visibility: 1,
        top: '9px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.8em',
      },
      '@media only screen and (max-device-width: 320px)': {
        fontSize: '1.7em',
      },
    },
    '&:hover': {
      background: '#00fbd5',
      borderColor: '#00c8aa',
      paddingRight: '4em',
      color: '#1f2d3d',
      transition: 'all 0.3s ease-out',
    },
    '&:visited': {
      background: '#00fbd5',
      color: '#1f2d3d',
    },
    '@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71) and (orientation:portrait)': {
      fontSize: '0.8em',
    },
    /*'@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71) and (orientation:landscape)': {
      marginRight: '1.5em',
    },*/
  },
  logo: {
    width: 60,
    height: 60,
    /*width: 345,
    height: 85,*/
    display: 'inline-flex',
    position: 'relative',
    top: '0',
  },
  headerWrapper: {
    position: 'fixed',
    width: '100%',
    zIndex: 999,
    top: '0',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    width: 'calc(100vw - 2em)',
    justifyContent: 'space-between',
    zIndex: 999,
    padding: '1em',
    [theme.breakpoints.down('sm')]: {
      color: '#000',
    }
  },
  section1: {
    backgroundImage: 'url("img/WebP/home-slide-1.webp"),url("img/JP2/home-slide-1.jp2"),url("img/JXR/home-slide-1.jxr"),url("img/home-slide-1.jpg")',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize : 'cover',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      backgroundImage: 'url("img/WebP/home-slide-1b.webp"),url("img/JP2/home-slide-1b.jp2"),url("img/JXR/home-slide-1b.jxr"),url("img/home-slide-1b.jpg")',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize : 'cover',
      paddingTop: '3em'
    },
    '@media (orientation: landscape)': {
        backgroundImage: 'url("img/WebP/home-slide-1_landscape.webp"),url("img/JP2/home-slide-1_landscape.jp2"),url("img/JXR/home-slide-1_landscape.jxr"),url("img/home-slide-1_landscape.jpg")',
        backgroundSize : 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
    },
  },
  mobileSection: {
    display: 'flex',
    height: '100vh',
    width: '100vw',
    backgroundColor: '#323a45',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
    }
  },
  section2: {

  },
  section3: {

  },
  page2A: {
    backgroundColor: '#323a45',
    overflow: 'hidden',
    height: '100vh',
    '&.tablet': {
      [theme.breakpoints.up('lg')]: {
        '@media (orientation: landscape)': {
          /*width: '50vw',
          display: 'inline-flex',*/
        },
      },
    }
  },
  page2B: {
    backgroundColor: '#323a45',
    height: '100vh',
    '&.tablet': {
      minHeight:'0',
      height: 'auto',
      [theme.breakpoints.up('lg')]: {
        '@media (orientation: landscape)': {
          /*width: '50vw',
          display: 'inline-flex',*/
        }
      },
    },
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      minHeight:'100vh',
      '@media only screen and (min-device-width : 375px) and (max-device-height : 812px) and (-webkit-device-pixel-ratio : 3) and (orientation : landscape)': {
        '& > div':{
          padding: '0 4.5em !important'
        }
      }
    }
  },
  page3A: {
    backgroundColor: '#323a45',
    height: '100vh',
    '&.tablet': {
      minHeight:'0',
      height: 'auto',
      [theme.breakpoints.up('lg')]: {
        '@media (orientation: landscape)': {
          /*width: '50vw',
          display: 'inline-flex',*/
        }
      },
    },
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      minHeight:'100vh',
      '&.tablet': {
        [theme.breakpoints.up('lg')]: {
          '@media (orientation: landscape)': {
            /*width: '50vw',
            display: 'inline-flex',*/
          },
        },
      }
    }
  },
  page3B: {
    backgroundColor: '#323a45',
    overflow: 'hidden',
    height: '100vh',
    '&.tablet': {
      [theme.breakpoints.up('lg')]: {
        '@media (orientation: landscape)': {
          /*width: '50vw',
          display: 'inline-flex',*/
        },
      },
    }
  },
  page4A: {
    backgroundColor: '#323a45',
    height: '100vh',
    position: 'relative',
    '&.tablet': {
      minHeight:'0',
      height: 'auto',
      [theme.breakpoints.up('lg')]: {
        '@media (orientation: landscape)': {
          /*width: '50vw',
          display: 'inline-flex',*/
        },
        '@media only screen and (min-device-width : 375px) and (max-device-height : 812px) and (-webkit-device-pixel-ratio : 3) and (orientation : portrait) and (display-mode : standalone)': {
          paddingLeft: '2em'
        }
      },
    },
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      minHeight:'100vh'
    }
  },
  page4B: {
    backgroundColor: '#323a45',
    height: '100vh',
    '&.tablet': {
      minHeight:'0',
      height: 'auto',
      [theme.breakpoints.up('lg')]: {
        '@media (orientation: landscape)': {
          /*width: '50vw',
          display: 'inline-flex',*/
        },
        '@media only screen and (min-device-width : 375px) and (max-device-height : 812px) and (-webkit-device-pixel-ratio : 3) and (orientation : portrait) and (display-mode : standalone)': {
          paddingLeft: '2em'
        }
      },
    },
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      minHeight:'100vh'
    }
  },
  img: {
    height: 'auto',
    verticalAlign: 'bottom',
    display: 'block',
    overflow: 'hidden',
    width:'100%',
  },
  SwipeableViewsContainer: {
    height: '100vh',
  },
  backgroundImageWrapper: {
    height: '100vh',
    width: '100%',
    '&.tablet': {
      width: '100vw',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
    }
  },
  backgroundImageContainer:{
    backgroundColor: 'rgba(0, 0, 0, 0)',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    height: '100%',
    width: '100%',
  },
  footerWrapper: {
    width: '220px',
    position: 'fixed',
    zIndex: 998,
    bottom: 0,
    right: 0,
    [theme.breakpoints.up('md')]: {
      zIndex: 1301
    }
  },
  footer: {
    textAlign: 'right',
    padding: '0.5em'
  },
  scrollIndicator: {
    zIndex: 9999,
    '&.elevation_1':{
      zIndex: 998,
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '48px',
      marginLeft: 'auto',
      marginRight: 'auto',
      left: 'calc(50% - 24px)',
      bottom: '2em',
      '@media only screen and (min-device-width : 375px) and (max-device-height : 812px) and (-webkit-device-pixel-ratio : 3) and (orientation : portrait)': {
        bottom: '8em',
      }
    }
  },
  arrow: {
    fontSize: '3em',
    opacity: 0.6,
    cursor: 'pointer',
    position: 'relative',
    zIndex: 998,
    [theme.breakpoints.down('sm')]: {
      opacity: 1,
      '@media (orientation: landscape)': {
        display: 'none',
      }
    }
  },
  offuscator: {
    position: 'absolute',
    width: '100vw',
    minHeight: '100vh',
    height: '100%',
    top: 0,
    backgroundColor: 'rgba(0,0,0,0.25)',
    left: 0,
    zIndex: 1,
  },
  intro: {
    position: 'relative',
    zIndex: 10,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  moreButton: {
    backgroundColor: '#ffffff',
    padding: '0.5em 1em',
    marginTop: '1em',
    [theme.breakpoints.down('sm')]: {
      '@media only screen and (orientation : portrait)': {
        marginTop: '8em',
      },
      '@media only screen and (min-device-width : 375px) and (max-device-height : 812px) and (-webkit-device-pixel-ratio : 3) and (orientation : portrait)': {
        marginTop: '12em',
      }
    },
  },
  introText: {
    fontSize: '1.3em',
    [theme.breakpoints.down('sm')]: {
      padding: '0 1em',
      fontSize: '1.1em',
    }
  },
  socialIconContainer: {
    position: 'relative',
    zIndex: 900,
    '& ul li': {
      listStyle: 'none',
      display: 'inline-flex',
      padding: '0.5em 1em',
    }
  },
  socialIconItem: {
    color: '#FFFFFF',
    opacity: 0.6,
    cursor: 'pointer',
    '&:hover': {
      opacity: 1,
      color: '#00c8aa',
    },
    '&.elevation_1':{
      color: '#000000',
      opacity: 1,
    },
    '&.elevation_1:hover':{
      color: '#00c8aa',
    },
  },
  iconArrowWrapper: {
    display: 'flex',
    width: '48px',
    height: '48px',
    alignSelf: 'center',
    justifySelf: 'center',
    marginLeft: 'calc(50% - 24px)',
  },
  textContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    '&.tablet': {
      alignItems: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 1.2em',
      alignItems: 'flex-start',
      minHeight: '100%',
      height: 'auto',
      flexDirection: 'column',
      marginBottom: '1em',
    }
  },
  textInner: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '504px',
    textAlign: 'left',
    padding: '4em',
    '&.tablet': {
      padding: '2em 0 4em 0',
      maxWidth: '650px',
    },
    '& p': {
      color: '#939da5',
    },
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      display: 'block',
      padding: '6em 0',
      '@media only screen and (min-device-width : 375px) and (max-device-height : 812px) and (-webkit-device-pixel-ratio : 3) and (orientation : landscape)': {
        '&.variant-2b':{
          maxWidth: '470px !important',
        },
        '&.variant-3a':{
          maxWidth: '470px !important',
          marginLeft: '3.2em'
        },
        '&.variant-4b':{
          maxWidth: '470px !important',
          marginLeft: '3.2em'
        },
        '&.variant-4a':{
          maxWidth: '460px !important',
          marginLeft: '3.2em'
        }
      }
    },
  },
  secondaryTitle: {
    fontFamily: "'Poiret One', cursive",
    fontSize: '3em',
    '&.tablet': {
      fontSize: '4em',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '2em',
      marginTop: '0em',
      marginBottom: '1em',
    }
  },
  featureIntro: {

  },
  featureContainer: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: '3em',
    }
  },
  featureIcon: {
    display: 'flex',
    color: 'rgb(3, 187, 255)',
    //color: 'rgb(255, 246, 0)',
    //color: 'rgb(0, 201, 171)',
    //marginRight: '1em',
  },
  miguelarolesSiteLink: {
    color: 'rgb(3, 187, 255)',
    marginLeft: '4px',
    textDecoration: 'none',
    fontWeight: 'bold',
  },
  featureTitle: {
    display: 'flex',
    fontFamily: "'Poiret One', cursive",
    fontSize: '1.8em',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0.3em',
    }
  },
  featureContent: {

  },
  mobileStepper: {
    backgroundColor: 'transparent',
    '& div > div[class*="MuiMobileStepper-dotActive-"]': {
      backgroundColor: '#FFFFFF',
    }
  },
  mobileStepperButton: {
    color: '#FFFFFF',
  },
  featuresSwipeableViews : {

  },
  mobileStepSection: {
    display: 'inline-flex',
    marginTop: '1em',
  },
  mobileStepSectionContainer: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    }
  },
  mobileStepSectionItem: {
    flex: 1,
    padding: '0 0.5em',
    [theme.breakpoints.down('sm')]: {

    }
  },
  teamTitle: {
    fontSize: '1.5em',
    marginBottom: '4px',
  },
  teamSubTitle: {
    color: '#939da5',
  },
  teamImage: {
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]:{
      '@media only screen and (orientation:landscape) and(maxWidth: 568px)': {
        maxWidth: '50%',
      },
    }
    //marginTop: '1em',
  },
  phoneLink: {
    color: '#FFFFFF',
    textDecoration: 'none',
  },
  footerCopyright: {
    fontSize: '0.8em',
    color: '#939da5',
    position: 'absolute',
    bottom: '1em',
    left: '3em',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      display: 'block',
      left: 0,
      bottom: '0',
      marginBottom: '4em',
      '@media only screen and (min-device-width : 375px) and (max-device-height : 812px) and (-webkit-device-pixel-ratio : 3) and (orientation : landscape)': {
        marginBottom: '2em',
      }
    }
  },
  brand: {
    fontFamily: "'Poiret One', cursive",
    fontSize: '2em',
    fontWeight: 'bold',
    margin: 0,
    position: 'relative',
    maxWidth: 'calc(100vw - 0em)',
    top: '-1em',
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'calc(100vw - 2em)',
      top: '-0.5em',
      fontSize: '1.5em',
    }
  },
  brandImage: {
    width: '800px',
    maxWidth: 'calc(100vw - 3em)',
    //left: '-0.8em',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      marginTop: '3em',
      marginBottom: '1.5em',
    }
  },
  headerTitle: {
    position: 'absolute',
    opacity: 0,
  },
  indicatorScroll: {
    cursor: 'pointer',
  },
  icon: {
    marginRight: '4px',
    display: 'inline-block',
    verticalAlign: 'middle'
  },
  buttonTextInner: {
    verticalAlign: 'middle',
    display: 'inline-block'
  },
  languageButton: {
    padding: 0,
    opacity: 0.6,
    '&:hover': {
      opacity: 1,
      color: '#00c8aa',
    },
    '&:hover *': {
      opacity: 1,
      color: '#00c8aa',
    },
    '& *': {
      fontSize: '1.2em'
    },
    '&.elevation_1':{
      opacity: 1
    },
    '&.elevation_1 *':{
      color: '#000',
      opacity: 1
    },
    '&.elevation_1:hover *':{
      color: '#00c8aa',
      opacity: 1
    },
    '&.elevation_1:hover':{
      backgroundColor: 'transparent'
    }
  },
  dropDownIcon: {
    color: '#FFFFFF',
    fontSize: '1.6em',
    top: '1px',
    position: 'relative'
  },
  languageItem: {
    '&.active': {
      backgroundColor: '#e3f2fd'
    }
  },
  buttonInner: {
    color: '#FFFFFF',
    textTransform: 'lowercase',
    fontWeight: 400
  },
  fixUl: {
    padding: 0,
    '& > li:first-child': {
      padding: 0
    }
  },
  flag: {
    marginRight: '8px'
  },
  fixFooterItem: {
    display: 'inline-block'
  },
  menu: {
    [theme.breakpoints.up('md')]:{
      zIndex: '99999 !important',
    }
  },
  popOverRoot: {
    [theme.breakpoints.up('md')]:{
      zIndex: '99999 !important',
      '& *':{
        zIndex: '99999 !important'
      }
    }
  }
});

/** @function createMuiTheme */
const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
        suppressDeprecationWarnings: true
    },
    palette: {
        primary: {
            main: '#FFFFFF'
        },
        secondary: {
            main: '#00c8aa'
        },
        error: {
            main: '#DD0031'
        }
    }
});


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialActiveSection: null,
      open: false,
      section2ActiveStep: 0,
      section3ActiveStep: 0,
      featuresActiveStep: 0,
      options: {
        licenseKey: '0795009F-7C3E4032-96FDC8F9-F17638E2',
        sectionClassName:     'section',
        anchors:              ['index', 'project', 'functionalities','contact'],
        scrollBar:            false,
        navigation:           true,
        verticalAlign:        true,
        sectionPaddingTop:    '0',
        sectionPaddingBottom: '0',
        arrowNavigation:      true,
        delay:                300,
        scrollingSpeed:       750
      },
      anchorElLanguage: null,
    };
  }

  componentDidMount() {
    const location_hash = window.location.hash;
    let dots = document.querySelectorAll('.Navigation a');

    if(location_hash && location_hash === '#/beta_waiting_list'){
      this.toggleDrawer(true);
    }

    [].forEach.call(dots, (dot) => {
      dot.style.backgroundColor = "#FFFFFF";
    });
  }

  sectionTwoRef = React.createRef();

  handleNext = () => {
    this.setState(prevState => ({
      featuresActiveStep: prevState.featuresActiveStep + 1,
    }));
  };

  handleBack = () => {
    this.setState(prevState => ({
      featuresActiveStep: prevState.featuresActiveStep - 1,
    }));
  };

  handleStepChange = activeStep => {
    this.setState({ section2ActiveStep: activeStep,section3ActiveStep: activeStep });
  };

  onScroll = (p) => {
    if (this.state.initialActiveSection === null) {
      this.setState(() => ({ initialActiveSection: p.activeSection }));
    }
  };

  toggleDrawer = (open) => {
    this.setState({
      open,
    });
  };

  handleScrollArrow = () => {
    const { width } = this.props;
    const section2 = document.querySelector('a[href="#sectionTwo"]');
    if(section2){
      section2.click();
    } else {
      const sectionAlter = document.querySelector('#alterScroll');
      if(sectionAlter){
        window.scrollTo({
          top: sectionAlter.offsetTop,
          left: 0,
          behavior: 'smooth'
        });
      }
    }

    if(width === 'xs' || width === 'sm'){
      const section2 = this.sectionTwoRef.current;
      window.scrollTo({top:section2.offsetTop,behavior: 'smooth'});
    }
  };

  handleClickLanguage = event => {
    this.setState({anchorElLanguage: event.currentTarget});
  };

  handleCloseLanguage = () => {
    this.setState({anchorElLanguage: null});
  };

  render(){
    const { initialActiveSection,open } = this.state;
    const { classes,width,t } = this.props;
    const { section2ActiveStep,section3ActiveStep,featuresActiveStep } = this.state;
    let section2Stepper;
    let section3Stepper;

    if(width === 'xs' || width === 'sm'){
      let section2ImagePathA;
      let section2ImagePathB;
      let section2ImagePathC;
      let section2ImagePathD;
      let section2ImagePathE;

      let section3ImagePathA;
      let section3ImagePathB;
      let section3ImagePathC;
      let section3ImagePathD;
      let section3ImagePathE;

      if((window.innerHeight > window.innerWidth)){
        if(isTablet){
          section2ImagePathA = 'slide-2';
          section2ImagePathB = 'slide-6';
          section2ImagePathC = 'slide-3';
          section2ImagePathD = 'slide-7';
          section2ImagePathE = 'slide-8';

          section3ImagePathA = 'slide-1';
          section3ImagePathB = 'slide-4';
          section3ImagePathC = 'slide-5';
          section3ImagePathD = 'slide-9';
          section3ImagePathE = 'slide-10';
        } else {
          section2ImagePathA = 'slide-2A';
          section2ImagePathB = 'slide-6A';
          section2ImagePathC = 'slide-3A';
          section2ImagePathD = 'slide-7A';
          section2ImagePathE = 'slide-8A';

          section3ImagePathA = 'slide-1A';
          section3ImagePathB = 'slide-4A';
          section3ImagePathC = 'slide-5A';
          section3ImagePathD = 'slide-9A';
          section3ImagePathE = 'slide-10A';
        }
      } else {
        section2ImagePathA = 'slide-2_landscape';
        section2ImagePathB = 'slide-6_landscape';
        section2ImagePathC = 'slide-3_landscape';
        section2ImagePathD = 'slide-7_landscape';
        section2ImagePathE = 'slide-8_landscape';

        section3ImagePathA = 'slide-1_landscape';
        section3ImagePathB = 'slide-4_landscape';
        section3ImagePathC = 'slide-5_landscape';
        section3ImagePathD = 'slide-9_landscape';
        section3ImagePathE = 'slide-10_landscape';
      }

      section2Stepper = [
        {
          label: 'section 2 image A',
          imgPath: section2ImagePathA,
        },
        {
          label: 'section 2 image B',
          imgPath: section2ImagePathB,
        },
        {
          label: 'section 2 image C',
          imgPath: section2ImagePathC,
        },
        {
          label: 'section 2 image D',
          imgPath: section2ImagePathD,
        },
        {
          label: 'section 2 image E',
          imgPath: section2ImagePathE,
        }
      ];

      section3Stepper = [
        {
          label: 'section 3 image A',
          imgPath: section3ImagePathA,
        },
        {
          label: 'section 3 image B',
          imgPath: section3ImagePathB,
        },
        {
          label: 'section 3 image C',
          imgPath: section3ImagePathC,
        },
        {
          label: 'section 3 image D',
          imgPath: section3ImagePathD,
        },
        {
          label: 'section 3 image E',
          imgPath: section3ImagePathE,
        }
      ];
    } else {
      if((width === 'lg' || width === 'xl') && isTablet){
        section2Stepper = [
          {
            label: 'section 2 image A',
            imgPath:'slide-2',
          },
          {
            label: 'section 2 image B',
            imgPath:'slide-6',
          },
          {
            label: 'section 2 image C',
            imgPath:'slide-3',
          },
          {
            label: 'section 2 image D',
            imgPath: 'slide-7',
          },
          {
            label: 'section 2 image E',
            imgPath: 'slide-8',
          }
        ];
        section3Stepper = [
          {
            label: 'section 3 image A',
            imgPath:'slide-1_landscape',
          },
          {
            label: 'section 3 image B',
            imgPath:'slide-4_landscape',
          },
          {
            label: 'section 3 image C',
            imgPath:'slide-5_landscape',
          },
          {
            label: 'section 3 image D',
            imgPath: 'slide-9_landscape',
          },
          {
            label: 'section 3 image E',
            imgPath: 'slide-10_landscape',
          }
        ];
      } else {
        section2Stepper = [
          {
            label: 'section 2 image A',
            imgPath: (isTablet && (window.innerHeight < window.innerWidth))?'slide-2_landscape':'slide-2',
          },
          {
            label: 'section 2 image B',
            imgPath: (isTablet && (window.innerHeight < window.innerWidth))?'slide-6_landscape':'slide-6',
          },
          {
            label: 'section 2 image C',
            imgPath: (isTablet && (window.innerHeight < window.innerWidth))?'slide-3_landscape':'slide-3',
          },
          {
            label: 'section 2 image D',
            imgPath: (isTablet && (window.innerHeight < window.innerWidth))?'slide-7_landscape':'slide-7',
          },
          {
            label: 'section 2 image E',
            imgPath: (isTablet && (window.innerHeight < window.innerWidth))?'slide-8_landscape':'slide-8',
          }
        ];
        section3Stepper = [
          {
            label: 'section 3 image A',
            imgPath: (isTablet && (window.innerHeight < window.innerWidth))?'slide-1_landscape':'slide-1',
          },
          {
            label: 'section 3 image B',
            imgPath: (isTablet && (window.innerHeight < window.innerWidth))?'slide-4_landscape':'slide-4',
          },
          {
            label: 'section 3 image C',
            imgPath: (isTablet && (window.innerHeight < window.innerWidth))?'slide-5_landscape':'slide-5',
          },
          {
            label: 'section 3 image D',
            imgPath: (isTablet && (window.innerHeight < window.innerWidth))?'slide-9_landscape':'slide-9',
          },
          {
            label: 'section 3 image E',
            imgPath: (isTablet && (window.innerHeight < window.innerWidth))?'slide-10_landscape':'slide-10',
          }
        ];
      }
    }

    const featuresSteps = [
      {
        left: {
          title: t("Interactive Map"),
          logo: <RoomIcon fontSize="large"/>,
          content: t("Ourse is an easy-to-use tool to help you find the right event for you based on your availability and location mobility. The search filter allows you to see all your options at a glance and in one click, choose the one that suits you."),
        },
        right: {
          title: t("Personal Coaching"),
          logo: <FaceIcon fontSize="large"/>,
          content: t("Go beyond your limits with an individual program designed for you. After an initial personalized evaluation, the coach guides you through a series of objectives."),
        },
      },
      {
        left: {
          title: t("Payment System"),
          logo: <CreditCardIcon fontSize="large"/>,
          content: t("Increase your revenue with payment options designed to reach a global audience. Your courses can also be delivered online to broaden your potential client base."),
        },
        right: {
          title: t("Planning Tools"),
          logo: <DateRangeIcon fontSize="large"/>,
          content: t("Use the planning module to easily create complete and optimized planning according to your needs. Reservations will automatically appear in your calendar. All subscribers have a profile that you can consult to get to know your audience and adjust your approach if necessary."),
        },
      },
      {
        left: {
          title: t("Diet and Training plan"),
          logo: <PublicIcon fontSize="large"/>,
          content: t("Ourse offers you a platform to exchange messages and publications so that you can interact with all your groups and meet new friends. Ourse also offers a range of solutions that will help you easily share documents like sports programs and meal plans."),
        },
        right: {
          title: t("Drive Generator"),
          logo: <FitnessIcon fontSize="large"/>,
          content: `${t("Unlike conventional fitness apps, Ourse uses data and algorithms to create a computer model of the user's level of fitness, from which it will generate optimal targeted training according to their personal needs.")} \n\n${t("The knowledge of the professional coaches in tandem with our awesome AI crammed in the app helps coaches to make workout sessions more productive and to achieve better results.")}`,
        },
      },
    ];
    const featuresMaxSteps = featuresSteps.length;

    return (
        <MuiThemeProvider theme={theme}>
            <div className={`App ${classes.root}`}>
              <Drawer anchor="right" open={this.state.open} onClose={()=>{this.toggleDrawer(false)}}>
                <div
                    tabIndex={0}
                    className={classes.list}
                >
                  <Info toggleDrawer={this.toggleDrawer}/>
                </div>
              </Drawer>
              <header className={classes.headerWrapper}>
                <div className={classes.header}>
                  <img className={classes.logo} alt={'Ourse App'} src={'./img/logo_light.svg'}/>
                  <Bounce>
                    <Button aria-label={t("Enroll for free")} variant="contained" className={`${classes.button} cto-button`} onClick={() => {this.toggleDrawer(true)}}>{t("Enroll for free")}<StarIcon className={'appearHover'}/></Button>
                  </Bounce>
                </div>
              </header>
              <footer className={classes.footerWrapper}>
                <div className={classes.footer}>
                  <div className={classes.socialIconContainer}>
                    <ul className={classes.fixUl}>
                      <li>
                        <Button
                            className={`${classes.languageButton} ${(open)?'elevation_1':''}`}
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={this.handleClickLanguage}
                        >
                          <span className={classes.buttonInner}>{(i18n.language && i18n.language === 'en')?'en':'fr'}</span><DownIcon className={classes.dropDownIcon}/>
                        </Button>
                        <Menu
                            classes={{
                              paper: classes.menu
                            }}
                            PopoverClasses={{
                              root: classes.popOverRoot
                            }}
                            id="language-menu"
                            anchorEl={this.state.anchorElLanguage}
                            keepMounted
                            open={Boolean(this.state.anchorElLanguage)}
                            onClose={this.handleCloseLanguage}
                        >
                          <MenuItem
                              onClick={()=>{
                                this.handleCloseLanguage();
                                i18n.changeLanguage('en');
                              }}
                              className={`${classes.languageItem} ${(i18n.language && i18n.language === 'en')?'active':''}`}
                          ><img className={classes.flag} src='/img/flags/flags-iso/flat/24/GB.png' alt='En flag' /> English</MenuItem>
                          <MenuItem
                              onClick={()=>{
                                this.handleCloseLanguage();
                                i18n.changeLanguage('fr');
                              }}
                              className={`${classes.languageItem} ${(i18n.language && i18n.language === 'fr')?'active':''}`}
                          ><img className={classes.flag} src='/img/flags/flags-iso/flat/24/FR.png' alt='Fr flag' /> Français</MenuItem>
                        </Menu>
                      </li>
                      <li>
                        <a className={`${classes.socialIconItem} ${(open)?'elevation_1':''}`} href={SOCIAL_FACEBOOK} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'facebook-f']} /></a>
                      </li>
                      <li>
                        <a className={`${classes.socialIconItem} ${(open)?'elevation_1':''}`} href={SOCIAL_TWITTER} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'twitter']} /></a>
                      </li>
                      <li>
                        <a className={`${classes.socialIconItem} ${(open)?'elevation_1':''}`} href={SOCIAL_INSTAGRAM} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'instagram']} /></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </footer>
              {
                ((width === 'md' || width === 'lg' || width === 'xl') && !isTablet )
                  ? <span>
                      <ReactFullpage
                          className={`${classes.container} container`}
                          {...this.state.options}
                          activeSection={initialActiveSection}
                          scrollCallback={this.onScroll}
                          render={({ state, fullpageApi }) => {
                            return (
                                <ReactFullpage.Wrapper>
                                  <section className={`custom-section section ${classes.section1}`} color="#323a45">
                                    <span className={classes.offuscator}> </span>

                                      <div className={`intro ${classes.intro}`}>
                                        <h1 className={classes.headerTitle}>Ourse</h1>
                                        <img src={'/img/logo_brand_light.svg'} alt={'Ourse logo'} className={classes.brandImage} />
                                        <Fade top cascade>
                                          <strong className={classes.brand}>{t("Find a sporting event near you")}</strong>
                                        </Fade>

                                        <Button aria-label={t("More Information")} onClick={() => fullpageApi.moveSectionDown()} variant="contained" className={classes.moreButton}>
                                          <InfoIcon className={classes.icon}/>
                                          <span className={classes.buttonTextInner}>{t("More Information")}</span>
                                        </Button>
                                      </div>
                                    <div className="scroll-indicator">
                                      <span id="indicator-scroll" className={classes.indicatorScroll}><i className={classes.iconArrowWrapper} onClick={() => fullpageApi.moveSectionDown()}><Arrow className={classes.arrow}/></i></span>
                                    </div>
                                  </section>
                                  <section className={`custom-section section ${classes.section2}`} color="#323a45">
                                    <Grid container spacing={0}>
                                      <Grid className={classes.page2A} item xs={12} sm={6}>
                                        <AutoPlaySwipeableViews
                                            axis={'x'}
                                            index={section2ActiveStep}
                                            onChangeIndex={this.handleStepChange}
                                            enableMouseEvents
                                            interval={10000}
                                        >
                                          {section2Stepper.map((step, index) => (
                                              <div className={classes.backgroundImageWrapper} key={step.label}>
                                                {Math.abs(section2ActiveStep - index) <= 2 ? (
                                                    <div className={classes.backgroundImageContainer} style={{
                                                      backgroundImage:`url("img/WebP/${step.imgPath}.webp"),url("img/JP2/${step.imgPath}.jp2"),url("img/JXR/${step.imgPath}.jxr"),url("img/${step.imgPath}.jpg")`
                                                    }}> </div>
                                                ) : null}
                                              </div>
                                          ))}
                                        </AutoPlaySwipeableViews>
                                      </Grid>
                                      <Grid className={classes.page2B} item xs={12} sm={6}>
                                        <Fade>
                                          <div className={classes.textContainer}>
                                            <div className={classes.textInner}>
                                              <Fade top cascade>
                                                <h2 className={classes.secondaryTitle}>{t("Our Project")}</h2>
                                              </Fade>
                                              <p>{t("Do you wish to participate in sports, run, dance, or do other physical activities, but are unable to do it on your own or don't know where to go near your home? Do you want to find more players for your football team, find a running or a tennis partner?")}</p>
                                              <p>{t("Or perhaps you are a coach, a dance teacher, or a yoga teacher and are interested in starting your activity as the head of a club or team. Do you want to pass on your knowledge joyfully, but don't know how to make yourself visible?")}</p>
                                              <p>{t("Ourse is the social platform created for you that makes all events related to your interests in your geographical area visible at a glance on an interactive map.")}</p>
                                              <p>{t("Planning/scheduling tools and a platform for exchanging messages facilitate the organization of public or private groups and events, both remotely or in-person.")}</p>
                                              <p>{t("Don't lose the opportunity to explore a new avenue to stay healthy and in a good mood.")}</p>
                                              <p>{t("Ourse provides you with the tools, resources, and guidance you need to help you lead a healthier life.")}</p>
                                            </div>
                                          </div>
                                        </Fade>
                                      </Grid>
                                    </Grid>
                                  </section>
                                  <section className={`custom-section section ${classes.section3}`} color="#323a45">
                                    <Grid container spacing={0}>
                                      <Grid className={classes.page3A} item xs={12} sm={6}>
                                        <Fade>
                                          <div className={classes.textContainer}>
                                            <div className={classes.textInner}>
                                              <Fade top cascade>
                                                <h2 className={classes.secondaryTitle}>{t("The Functionalities")}</h2>
                                              </Fade>
                                              <p className={classes.featureIntro}>{t("Ourse has everything you would expect from an advanced management platform, and more!")}</p>
                                              <AutoPlaySwipeableViews
                                                  axis={'x'}
                                                  index={featuresActiveStep}
                                                  onChangeIndex={()=>{/*this.handleStepChange*/}}
                                                  className={classes.featuresSwipeableViews}
                                                  enableMouseEvents
                                              >
                                                {featuresSteps.map((step, index) => (
                                                    <div className={classes.mobileStepSection} key={`${step.left.title}-${step.right.title}-${index}`}>
                                                      {Math.abs(featuresActiveStep - index) <= 2 ? (
                                                          <div className={classes.mobileStepSectionContainer}>
                                                            <div className={classes.mobileStepSectionItem}>
                                                              <div className={classes.featureContainer}>
                                                                <span className={classes.featureIcon}>{step.left.logo}</span>
                                                                <h3 className={classes.featureTitle}>{step.left.title}</h3>
                                                                <p className={classes.featureContent}>{step.left.content}</p>
                                                              </div>
                                                            </div>
                                                            <div className={classes.mobileStepSectionItem}>
                                                              <div className={classes.featureContainer}>
                                                                <span className={classes.featureIcon}>{step.right.logo}</span>
                                                                <h3 className={classes.featureTitle}>{step.right.title}</h3>
                                                                <p className={classes.featureContent}>{step.right.content}</p>
                                                              </div>
                                                            </div>
                                                          </div>
                                                      ) : null}
                                                    </div>
                                                ))}
                                              </AutoPlaySwipeableViews>
                                              <Grid container spacing={2}>
                                                <Grid item xs={12} sm={12}>
                                                  <MobileStepper
                                                      steps={featuresMaxSteps}
                                                      position="static"
                                                      activeStep={featuresActiveStep}
                                                      className={classes.mobileStepper}
                                                      nextButton={
                                                        <Button aria-label={'Next arrow'} className={classes.mobileStepperButton} size="small" onClick={this.handleNext} disabled={featuresActiveStep === featuresMaxSteps - 1}>
                                                          {<KeyboardArrowRight fontSize={'large'} />}
                                                        </Button>
                                                      }
                                                      backButton={
                                                        <Button aria-label={'Back arrow'} className={classes.mobileStepperButton} size="small" onClick={this.handleBack} disabled={featuresActiveStep === 0}>
                                                          {<KeyboardArrowLeft fontSize={'large'} />}
                                                        </Button>
                                                      }
                                                  />
                                                </Grid>
                                              </Grid>
                                            </div>
                                          </div>
                                        </Fade>
                                      </Grid>
                                      <Grid className={classes.page3B} item xs={12} sm={6}>
                                        <AutoPlaySwipeableViews
                                            axis={'x'}
                                            index={section3ActiveStep}
                                            onChangeIndex={this.handleStepChange}
                                            enableMouseEvents
                                            interval={10000}
                                        >
                                          {section3Stepper.map((step, index) => (
                                              <div className={classes.backgroundImageWrapper} key={step.label}>
                                                {Math.abs(section3ActiveStep - index) <= 2 ? (
                                                    <div className={classes.backgroundImageContainer} style={{
                                                      backgroundImage:`url("img/WebP/${step.imgPath}.webp"),url("img/JP2/${step.imgPath}.jp2"),url("img/JXR/${step.imgPath}.jxr"),url("img/${step.imgPath}.jpg")`
                                                    }}> </div>
                                                ) : null}
                                              </div>
                                          ))}
                                        </AutoPlaySwipeableViews>
                                      </Grid>
                                    </Grid>
                                  </section>
                                  <section className={`custom-section section`} color="#323a45">
                                    <Grid container spacing={0}>
                                      <Grid item xs={12} sm={6}>
                                        <Fade>
                                          <div className={classes.textContainer}>
                                            <div className={classes.textInner}>
                                              <Fade top cascade>
                                                <h2 className={classes.secondaryTitle}>{t("Get in touch")}</h2>
                                              </Fade>
                                              <p className="subtitle">
                                                {t("Contact us via ")} <strong>{t("Email")}</strong> : <a href={`mailto:${SUPPORT_MAIL}`} className={classes.phoneLink}>{SUPPORT_MAIL}</a>
                                              </p>
                                              <ContactComponent />
                                            </div>
                                            <p className={classes.footerCopyright}>
                                              <span>{`©${new Date().getFullYear()}`}</span>
                                              <a className={classes.miguelarolesSiteLink} target="_blank" rel="noreferrer noopener" href="#" >Miguel</a>
                                              <span className={classes.fixFooterItem}>{`${t("  - To make the world a better place")}`}</span>
                                            </p>
                                          </div>
                                        </Fade>
                                      </Grid>
                                      <Grid className={classes.page4A} item xs={12} sm={6}>
                                        <Fade>
                                          <div className={classes.textContainer}>
                                            <div className={classes.textInner}>
                                              <Fade top cascade>
                                                <h2 className={classes.secondaryTitle}>{t("Our Team")}</h2>
                                              </Fade>
                                              <Grid container spacing={2}>
                                                <Grid item xs={12} sm={6}>
                                                  <img src={"./img/member-2.jpg"} alt="CEO" className={classes.teamImage}/>
                                                  <h3 className={classes.teamTitle}>Miguel</h3>
                                                  <small className={classes.teamSubTitle}>{t("FOUNDER - CEO")}</small>
                                                  <p>{t("Miguel is a developer, artist, and entrepreneur. Passionate about new technologies and their positive impacts on the society, he founded and developed Ourse to help communities in their quest for a healthier life.")}</p>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                  <img src={"./img/member-1.jpg"} alt="COM" className={classes.teamImage}/>
                                                  <h3 className={classes.teamTitle}>Julie</h3>
                                                  <small className={classes.teamSubTitle}>{t("COMMUNICATION MANAGER")}</small>
                                                  <p>{t("Julie is a professional architect and the communication manager of Ourse. Sensitive to the dynamics of social cohesion through the appropriation of public space, Julie has joined forces with the project Ourse, a real social catalyst.")}</p>
                                                </Grid>
                                              </Grid>
                                            </div>
                                          </div>
                                        </Fade>
                                      </Grid>
                                    </Grid>
                                  </section>
                                </ReactFullpage.Wrapper>
                            );
                          }}
                      >
                      </ReactFullpage>
                    </span>
                    : <div>
                        <section className={`${classes.mobileSection} ${classes.section1} ${(isTablet)?'tablet':''}`}>
                          <span className={classes.offuscator}> </span>
                          <Fade>
                            <div className={`intro ${classes.intro}`}>
                              <h1 className={classes.headerTitle}>Ourse</h1>
                              <img src={'/img/logo_brand_light.svg'} alt={'Ourse logo'} className={classes.brandImage} />
                              <strong className={classes.brand}>{t("Find a sporting event near you")}</strong>
                              <Button aria-label={t("More Information")} onClick={this.handleScrollArrow} variant="contained" className={classes.moreButton}>{t("More Information")}</Button>
                            </div>
                          </Fade>
                          <div className={`scroll-indicator ${classes.scrollIndicator} ${(open)?'elevation_1':''}`}>
                            <span id="indicator-scroll" className={classes.indicatorScroll}><i className={classes.iconArrowWrapper} onClick={this.handleScrollArrow}><Arrow className={classes.arrow}/></i></span>
                          </div>
                        </section>
                        <section id="alterScroll" ref={this.sectionTwoRef} className={`${classes.mobileSection} ${classes.page2B} ${(isTablet)?'tablet':''}`}>
                          <Fade>
                            <div className={`${classes.textContainer} ${(isTablet)?'tablet':''}`}>
                              <div className={`${classes.textInner} variant-2b ${(isTablet)?'tablet':''}`}>
                                <h2 className={`${classes.secondaryTitle} ${(isTablet)?'tablet':''}`}>{t("Our Project")}</h2>
                                <p>{t("Do you wish to participate in sports, run, dance, or do other physical activities, but are unable to do it on your own or don't know where to go near your home? Do you want to find more players for your football team, find a running or a tennis partner?")}</p>
                                <p>{t("Or perhaps you are a coach, a dance teacher, or a yoga teacher and are interested in starting your activity as the head of a club or team. Do you want to pass on your knowledge joyfully, but don't know how to make yourself visible?")}</p>
                                <p>{t("Ourse is the social platform created for you that makes all events related to your interests in your geographical area visible at a glance on an interactive map.")}</p>
                                <p>{t("Planning/scheduling tools and a platform for exchanging messages facilitate the organization of public or private groups and events, both remotely or in-person.")}</p>
                                <p>{t("Don't lose the opportunity to explore a new avenue to stay healthy and in a good mood.")}</p>
                                <p>{t("Ourse provides you with the tools, resources, and guidance you need to help you lead a healthier life.")}</p>
                              </div>
                            </div>
                          </Fade>
                        </section>
                        <section className={`${classes.mobileSection} ${classes.page2A} ${(isTablet)?'tablet':''}`}>
                          <AutoPlaySwipeableViews
                              axis={'x'}
                              index={section2ActiveStep}
                              onChangeIndex={this.handleStepChange}
                              enableMouseEvents
                              interval={10000}
                          >
                            {section2Stepper.map((step, index) => (
                                <div className={`${classes.backgroundImageWrapper} ${(isTablet)?'tablet':''}`} key={step.label}>
                                  {Math.abs(section2ActiveStep - index) <= 2 ? (
                                      <div className={`${classes.backgroundImageContainer} ${(isTablet)?'tablet':''}`} style={{
                                        backgroundImage:`url("img/WebP/${step.imgPath}.webp"),url("img/JP2/${step.imgPath}.jp2"),url("img/JXR/${step.imgPath}.jxr"),url("img/${step.imgPath}.jpg")`
                                      }}> </div>
                                  ) : null}
                                </div>
                            ))}
                          </AutoPlaySwipeableViews>
                        </section>
                        <section className={`${classes.mobileSection} ${classes.page3A} ${(isTablet)?'tablet':''}`}>
                            <div className={`${classes.textContainer} ${(isTablet)?'tablet':''}`}>
                              <div className={`${classes.textInner} variant-3a ${(isTablet)?'tablet':''}`}>
                                <h2 className={`${classes.secondaryTitle} ${(isTablet)?'tablet':''}`}>{t("The Functionalities")}</h2>
                                <p className={classes.featureIntro}>{t("Ourse has everything you would expect from an advanced management platform, and more!")}</p>
                                {featuresSteps.map((step, index) => (
                                    <div className={`${classes.mobileStepSection} ${(isTablet)?'tablet':''}`} key={`${step.left.title}-${step.right.title}-${index}`}>
                                      {Math.abs(featuresActiveStep - index) <= 2 ? (
                                          <div className={`${classes.mobileStepSectionContainer} ${(isTablet)?'tablet':''}`}>
                                            <div className={`${classes.mobileStepSectionItem} ${(isTablet)?'tablet':''}`}>
                                              <div className={`${classes.featureContainer} ${(isTablet)?'tablet':''}`}>
                                                <span className={classes.featureIcon}>{step.left.logo}</span>
                                                <h3 className={classes.featureTitle}>{step.left.title}</h3>
                                                <p className={classes.featureContent}>{step.left.content}</p>
                                              </div>
                                            </div>
                                            <div className={`${classes.mobileStepSectionItem} ${(isTablet)?'tablet':''}`}>
                                              <div className={`${classes.featureContainer} ${(isTablet)?'tablet':''}`}>
                                                <span className={classes.featureIcon}>{step.right.logo}</span>
                                                <h3 className={classes.featureTitle}>{step.right.title}</h3>
                                                <p className={classes.featureContent}>{step.right.content}</p>
                                              </div>
                                            </div>
                                          </div>
                                      ) : null}
                                    </div>
                                ))}
                              </div>
                            </div>
                        </section>
                        <section className={`${classes.mobileSection} ${classes.page3B} ${(isTablet)?'tablet':''}`}>
                          <AutoPlaySwipeableViews
                              axis={'x'}
                              index={section3ActiveStep}
                              onChangeIndex={this.handleStepChange}
                              enableMouseEvents
                              interval={10000}
                          >
                            {section3Stepper.map((step, index) => (
                                <div className={`${classes.backgroundImageWrapper} ${(isTablet)?'tablet':''}`} key={step.label}>
                                  {Math.abs(section3ActiveStep - index) <= 2 ? (
                                      <div className={`${classes.backgroundImageContainer} ${(isTablet)?'tablet':''}`} style={{
                                        backgroundImage:`url("img/WebP/${step.imgPath}.webp"),url("img/JP2/${step.imgPath}.jp2"),url("img/JXR/${step.imgPath}.jxr"),url("img/${step.imgPath}.jpg")`
                                      }}> </div>
                                  ) : null}
                                </div>
                            ))}
                          </AutoPlaySwipeableViews>
                        </section>
                        <section className={`${classes.mobileSection} ${classes.page4B} ${(isTablet)?'tablet':''}`}>
                          <Fade>
                            <div className={`${classes.textContainer} ${(isTablet)?'tablet':''}`}>
                              <div className={`${classes.textInner} variant-4b ${(isTablet)?'tablet':''}`}>
                                <h2 className={`${classes.secondaryTitle} ${(isTablet)?'tablet':''}`}>{t("Our Team")}</h2>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm={6}>
                                    <img src={"./img/member-2.jpg"} alt="CEO" className={`${classes.teamImage} ${(isTablet)?'tablet':''}`}/>
                                    <h3 className={classes.teamTitle}>Miguel</h3>
                                    <small className={classes.teamSubTitle}>{t("FOUNDER - CEO")}</small>
                                    <p>{t('Miguel is a developer, artist, and entrepreneur. Passionate about new technologies and their positive impacts on the society, he founded and developed Ourse to help communities in their quest for a healthier life.')}</p>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <img src={"./img/member-1.jpg"} alt="COM" className={`${classes.teamImage} ${(isTablet)?'tablet':''}`}/>
                                    <h3 className={classes.teamTitle}>Julie</h3>
                                    <small className={classes.teamSubTitle}>{t("COMMUNICATION MANAGER")}</small>
                                    <p>{t("Julie is a professional architect and the communication manager of Ourse. Sensitive to the dynamics of social cohesion through the appropriation of public space, Julie has joined forces with the project Ourse, a real social catalyst.")}</p>
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                          </Fade>
                        </section>
                        <section className={`${classes.mobileSection} ${classes.page4A} ${(isTablet)?'tablet':''}`}>
                          <Fade>
                            <div className={`${classes.textContainer} ${(isTablet)?'tablet':''}`}>
                              <div className={`${classes.textInner} variant-4a ${(isTablet)?'tablet':''}`}>
                                <h2 className={`${classes.secondaryTitle} ${(isTablet)?'tablet':''}`}>{t("Get in touch")}</h2>
                                <p className="subtitle">
                                  {t("Contact us via ")} <strong>{t("Email")}</strong> : <a href={`mailto:${SUPPORT_MAIL}`} className={classes.phoneLink}>{SUPPORT_MAIL}</a>
                                </p>
                                <ContactComponent />
                              </div>
                              <p className={classes.footerCopyright}>
                                <span>{`©${new Date().getFullYear()}`}</span>
                                <a className={classes.miguelarolesSiteLink} target="_blank" rel="noreferrer noopener" href="#" >Miguel</a>
                                <span>{`${t("  - To make the world a better place")}`}</span>
                              </p>
                            </div>
                          </Fade>
                        </section>
                      </div>
              }
            </div>
        </MuiThemeProvider>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withWidth()(withTranslation('app')(App)));
